// extracted by mini-css-extract-plugin
export var bottom = "Footer-module--bottom--07c5f";
export var contact = "Footer-module--contact--e1eaa";
export var email = "Footer-module--email--eb0b3";
export var facebook = "Footer-module--facebook--a1b58";
export var footer = "Footer-module--footer--34cdd";
export var hotline = "Footer-module--hotline--dc067";
export var instagram = "Footer-module--instagram--5d7e8";
export var logoSocial = "Footer-module--logo-social--806e8";
export var menu = "Footer-module--menu--f7dc1";
export var nav = "Footer-module--nav--80a3d";
export var pageFooter = "Footer-module--page-footer--a8dac";
export var phone = "Footer-module--phone--e0726";
export var section = "Footer-module--section--91df9";
export var sections = "Footer-module--sections--31d5d";
export var sltLogo = "Footer-module--slt-logo--7f80f";
export var social = "Footer-module--social--5e25a";
export var socialIcons = "Footer-module--socialIcons--abb53";
export var textCenter = "Footer-module--textCenter--d6950";
export var times = "Footer-module--times--07958";
export var top = "Footer-module--top--b2130";
export var topContent = "Footer-module--topContent--e8300";
export var twitter = "Footer-module--twitter--e897d";
export var verticalMenu = "Footer-module--vertical-menu--173e9";
export var youtube = "Footer-module--youtube--cdbe0";