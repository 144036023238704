// extracted by mini-css-extract-plugin
export var black = "ContentArea-module--black--17d4e";
export var bottom20 = "ContentArea-module--bottom-20--4d87f";
export var bottom30 = "ContentArea-module--bottom-30--21b8b";
export var bottom55 = "ContentArea-module--bottom-55--71d0e";
export var bottom55Center = "ContentArea-module--bottom-55-center--aedfc";
export var bottom60 = "ContentArea-module--bottom-60--fd1bc";
export var bottom80 = "ContentArea-module--bottom-80--0874d";
export var center = "ContentArea-module--center--7a7a0";
export var contentArea = "ContentArea-module--content-area--ec2b8";
export var grey = "ContentArea-module--grey--e6d40";
export var infoarea = "ContentArea-module--infoarea--e4038";
export var noSpacing = "ContentArea-module--no-spacing--f4d1c";
export var paddingTop80MarginBottom100 = "ContentArea-module--padding-top-80-margin-bottom-100--06863";
export var pageFooter = "ContentArea-module--page-footer--d336e";
export var textCenter = "ContentArea-module--textCenter--1ed88";
export var top80 = "ContentArea-module--top-80--65221";
export var top80Grey = "ContentArea-module--top-80-grey--5dfa6";
export var white = "ContentArea-module--white--eff96";