// extracted by mini-css-extract-plugin
export var cluster = "Pages-module--cluster--89d60";
export var clusterTeaser = "Pages-module--cluster-teaser--d16d5";
export var graphicSeparation = "Pages-module--graphic-separation--9ace3";
export var m1 = "Pages-module--m1--0cf94";
export var m2 = "Pages-module--m2--ae581";
export var m3 = "Pages-module--m3--ad825";
export var m4 = "Pages-module--m4--2dbe4";
export var pageFooter = "Pages-module--page-footer--6f109";
export var pageMain = "Pages-module--page-main--ae9f7";
export var pageMainPost = "Pages-module--page-main-post--aa4be";
export var pageSkiatlas = "Pages-module--page-skiatlas--49297";
export var pageSkiatlasOverview = "Pages-module--page-skiatlas-overview--61720";
export var pageTtg = "Pages-module--page-ttg--dcb9d";
export var postContent = "Pages-module--post-content--21307";
export var resultsCount = "Pages-module--results-count--f8b95";
export var textCenter = "Pages-module--textCenter--ac2aa";
export var ttgCol = "Pages-module--ttg-col--f002a";
export var ttgCols = "Pages-module--ttg-cols--d924f";